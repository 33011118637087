import React from 'react'
import './App.css'
import Navigation from './modules/Navigation'
import IntroVideo from './modules/IntroVideo'
import About from './modules/About'
import Artists from './modules/Artists'
import Gallery from './modules/Gallery'
import Shop from './modules/Shop'
import SPARequest from './modules/SPARequest'
import SPA from './modules/SPA'
import Contact from './modules/Contact'
import StudentsForJustice from './modules/StudentsForJustice'
import RSVPAllIn from './modules/RSVPAllIn'
import TheTerritory from './modules/TheTerritory'
import PosterSubmission from './modules/PosterSubmission'
import DesignContest from './modules/DesignContest'
import Rules from './modules/DesignContest/rules'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"

function App() {
  return (
    <Router>
      <div className="App">
        <Navigation />
        <Switch>
          <Route path="/students-for-justice">
            <StudentsForJustice />
          </Route>
          <Route path="/all-in">
            <RSVPAllIn />
          </Route>
          <Route path="/the-territory">
            <TheTerritory />
          </Route>
          <Route path="/designcontest">
            <PosterSubmission />
          </Route>
          {/* <Route path="/designcontest">
            <DesignContest />
          </Route> */}
          <Route path="/spa">
            <SPA />
          </Route>
          <Route path="/spa-request">
            <SPARequest />
          </Route>
          <Route path="/rules">
            <Rules />
          </Route>
          <Route path="/">
            <IntroVideo />
            <About />
            <Artists />
            <Gallery />
            {/* <Shop /> */}
            <Contact />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
